import React, { useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import Chart from 'react-apexcharts';
import { Grid } from '@material-ui/core';

import {
  Price,
  fromMomentToUTCNumber,
  setTimeToInitOf,
  Button,
  Loading,
  fromNumberToUTCMoment,
  Modal,
  TextPoint,
  CardComponent,
  ProductCard
} from '@wdynamo/common';

import modalCloseIcon from '../../../assets/img/icons_dynamo/icon_close.svg';
// eslint-disable-next-line no-unused-vars
import { IMySalesDashboardProps, ISoldItems, ISoldItem, IChartPeriods } from '../models';

interface IDashboardProps {
  [key: string]: any;
  dashboardData: IMySalesDashboardProps;
  className?: string;
}

const CleanDashboard: React.FC<IDashboardProps> = (props: IDashboardProps) => {
  const { dashboardData } = props;
  const { t } = useTranslation();
  const [selectedChartPeriod, setSelectedChartPeriod] = useState<IChartPeriods>(IChartPeriods.DAILY);
  const [openModal, setOpenModal] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState<any>({});

  const renderMostSoldItems = (): React.ReactNode => {
    const { loading, items } = dashboardData;
    if (loading) return <Loading />;
    else {
      const soldItems: ISoldItems = {};
      items.forEach((item: any) => {
        const listOfSoldItems = item.items as Array<any>;
        listOfSoldItems.forEach((soldItem: any) => {
          soldItems[soldItem.product.code] = {
            ...soldItems[soldItem.code],
            price: soldItem.product.price.amount,
            title: soldItem.product.title,
            amount: soldItems[soldItem.product.code]?.amount ? soldItems[soldItem.product.code].amount + 1 : 1,
            shortDescription: soldItem.product.title,
            description: soldItem.product.description
          };
        });
      });

      return (
        <>
          {Object.keys(soldItems).map((key: string, index: number) => {
            const soldItem: ISoldItem = soldItems[key];
            return (
              <CardComponent
                key={index}
                //@TODO: replace the image for the real image.
                cardMedia={{
                  src: process.env.REACT_APP_DEFAULT_PRODUCT_IMG,
                  alt: soldItem.title
                }}
                cardContent={
                  <>
                    <h4>{soldItem.title}</h4>
                    <Price amount={soldItem.price} className='price' />
                  </>
                }
                className={`${index < 2 && 'salient'} sold-item-card`}
                onClick={() => {
                  setSelectedOrder(soldItem);
                  setOpenModal(true);
                }}
              />
            );
          })}
        </>
      );
    }
  };

  const calculateChartData = (): { [key: string]: number } => {
    const { items } = dashboardData;
    const salesByDay: { [key: string]: number } = {};
    items.forEach((item: any) => {
      const listOfSoldItems = item.items as Array<any>;
      let createdAt: number;
      switch (selectedChartPeriod) {
        case IChartPeriods.DAILY:
          createdAt = fromMomentToUTCNumber(setTimeToInitOf(item.created_at, 'day'));
          break;
        case IChartPeriods.WEEKLY:
          createdAt = fromMomentToUTCNumber(setTimeToInitOf(item.created_at, 'week'));
          break;
        case IChartPeriods.MONTHLY:
          createdAt = fromMomentToUTCNumber(setTimeToInitOf(item.created_at, 'month'));
          break;
        default:
          createdAt = fromMomentToUTCNumber(setTimeToInitOf(item.created_at, 'day'));
          break;
      }
      salesByDay[createdAt]
        ? (salesByDay[createdAt] += listOfSoldItems.length)
        : (salesByDay[createdAt] = listOfSoldItems.length);
    });
    return salesByDay;
  };

  const renderMostSoldChart = (): React.ReactNode => {
    const { loading, items } = dashboardData;
    if (loading) return <Loading />;
    else if (!items.length) return <></>;
    else {
      const salesByDay: { [key: string]: number } = calculateChartData();

      const series = [
        {
          data: Object.entries(salesByDay)
            .map((item: [string, number]): [number, number] => [parseInt(item[0]), item[1]])
            .reverse()
        }
      ];
      const options = {
        chart: {
          id: 'area-datetime',
          type: 'area',
          height: 350,
          zoom: {
            autoScaleYaxis: true
          }
        },
        dataLabels: {
          enabled: false
        },
        markers: {
          size: 0,
          style: 'hollow'
        },
        xaxis: {
          type: 'datetime',
          min: Object.keys(salesByDay).slice(-1)[0],
          tickAmount: 6,
          categories: Object.entries(salesByDay)
            .map((item: [string, number]): number => parseInt(item[0]))
            .reverse(),
          labels: {
            show: true,
            formatter: (val: string) => {
              const xTime = fromNumberToUTCMoment(val);
              let xFormatedTime: string;
              switch (selectedChartPeriod) {
                case IChartPeriods.DAILY:
                  xFormatedTime = xTime.format('DD/MM');
                  break;
                case IChartPeriods.WEEKLY:
                  xFormatedTime = xTime.format('DD/MM');
                  break;
                case IChartPeriods.MONTHLY:
                  xFormatedTime = xTime.format('MMMM');
                  break;
                default:
                  xFormatedTime = xTime.format('DD/MM');
                  break;
              }
              return xFormatedTime;
            }
          }
        },
        yaxis: {
          type: 'numeric',
          min: 0,
          forceNiceScale: true,
          floating: false
        },
        tooltip: {
          x: {
            format: 'dd MMM yyyy'
          }
        },
        colors: ['#fd9a3f'],
        fill: {
          type: 'gradient',
          gradient: {
            shadeIntensity: 1,
            opacityFrom: 0.7,
            opacityTo: 0.9,
            stops: [0, 100]
          },
          colors: ['#fd9a3f']
        },
        grid: {
          xaxis: {
            lines: {
              show: true
            }
          },
          yaxis: {
            lines: {
              show: true
            }
          }
        }
      };

      return (
        <Grid container className='chart-content'>
          <Grid item xs={12} className='chart'>
            <Chart options={options} series={series} type='area' height={350} />
          </Grid>
          <Grid item xs={12} className='buttons'>
            <Button
              className={`${selectedChartPeriod === IChartPeriods.MONTHLY && 'active'}`}
              onClick={() => setSelectedChartPeriod(IChartPeriods.MONTHLY)}
            >
              {t('MY_SALES.DASHBOARD.CHART.BUTTONS.MONTH')}
            </Button>
            <Button
              className={`${selectedChartPeriod === IChartPeriods.WEEKLY && 'active'}`}
              onClick={() => setSelectedChartPeriod(IChartPeriods.WEEKLY)}
            >
              {t('MY_SALES.DASHBOARD.CHART.BUTTONS.WEEK')}
            </Button>
            <Button
              className={`${selectedChartPeriod === IChartPeriods.DAILY && 'active'}`}
              onClick={() => setSelectedChartPeriod(IChartPeriods.DAILY)}
            >
              {t('MY_SALES.DASHBOARD.CHART.BUTTONS.DAY')}
            </Button>
          </Grid>
        </Grid>
      );
    }
  };

  const renderDashboard = (): React.ReactNode => (
    <Grid container spacing={5} className='my-sales-dashboard'>
      <Grid item xs={12} md={6} className='chart-container'>
        <Grid container>
          <Grid item xs={12}>
            <h3>
              {t('MY_SALES.DASHBOARD.DASHBOARD.TITLE')}
              <TextPoint />
            </h3>
          </Grid>
          <Grid item xs={12}>
            {renderMostSoldChart()}
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12} md={6} className='most-sold'>
        <Grid container>
          <Grid item xs={12}>
            <h3>
              {t('MY_SALES.DASHBOARD.MOST_SOLD.TITLE')}
              <TextPoint />
            </h3>
          </Grid>
          <Grid item xs={12}>
            {renderMostSoldItems()}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );

  const renderModal = (): React.ReactNode => (
    <Modal
      closeIcon={<img src={modalCloseIcon} />}
      open={openModal}
      handleClose={() => {
        setSelectedOrder({});
        setOpenModal(false);
      }}
      className='order-modal'
      body={
        <ProductCard
          className='item-modal'
          imageToRight={true}
          product={{
            //@TODO: replace the image for the real image.
            thumbnail: {
              url: process.env.REACT_APP_DEFAULT_PRODUCT_IMG,
              alt: selectedOrder.title
            },
            imageToRight: true,
            title: selectedOrder.title,
            short_description: selectedOrder.shortDescription,
            description: selectedOrder.description,
            price: { amount: selectedOrder.price },
            featured: false
          }}
        />
      }
    />
  );

  return (
    <div className={props.className}>
      {renderDashboard()}
      {renderModal()}
    </div>
  );
};

export const Dashboard = styled(({ ...props }) => <CleanDashboard {...props} />)`
  .my-sales-dashboard {
    margin-bottom: 40px;
    .most-sold {
      .sold-item-card {
        margin: 15px 0;
        box-shadow: none;
        &:first-child {
          margin-top: 0;
        }
        &:last-child {
          margin-bottom: 0;
        }
        &.salient {
          background-color: var(--color-orange);
          border-color: var(--color-orange);
          .card-area {
            .card-content {
              h4,
              .price {
                color: var(--color-white);
                font-size: 20px;
                margin-top: 0;
              }
            }
          }
        }
        .card-area {
          display: flex;
          .card-img {
            width: 50%;
            height: 100%;
            min-height: 120px;
          }
          .card-content {
            width: 100%;
          }
        }
      }
    }
    .chart-container {
      .chart-content {
        .chart {
        }
        .buttons {
          display: flex;
          justify-content: space-between;
          button {
            background-color: transparent;
            border: none;
            color: var(--color-grey-text);
            box-shadow: none;
            padding: 5px 50px;
            border-radius: 20px;
            text-transform: none;
            &.active {
              border: 1px solid var(--color-orange);
              color: var(--color-orange);
            }
          }
        }
      }
    }
  }
`;
