import { call, put, takeLatest, all } from 'redux-saga/effects';

import Api from './Api';
import ApiMock from './ApiMock';
import { addUserSuccess, addUserFail, getRolesSuccess, getRolesFail } from './actions';
// eslint-disable-next-line no-unused-vars
import { ACTIONS, actionType } from '../ActionTypes';

const api = process.env.REACT_APP_USE_MOCKS === 'true' ? ApiMock() : Api();

function* sagasGetRolesFetch() {
  try {
    const response = yield call(api.getRolesFetch);
    yield put(getRolesSuccess(response.data.data.user));
  } catch (e) {
    yield put(getRolesFail(e));
  }
}

function* sagasAddUserFetch(action: actionType) {
  try {
    yield call(api.addUserFetch, action.payload.data);
    yield put(addUserSuccess());
  } catch (e) {
    yield put(addUserFail(e.response.data));
  }
}

// Listen for redux actions
function* addUserSaga() {
  yield all([takeLatest(ACTIONS.ADD_USER.GET_ROLES.FETCH, sagasGetRolesFetch)]);
  yield all([takeLatest(ACTIONS.ADD_USER.ADD.FETCH, sagasAddUserFetch)]);
}

export { addUserSaga };
