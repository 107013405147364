import { ACTIONS } from '../ActionTypes';

// Search Users
export const getUsersFetch = (partnerId: string, collaboratorId: string) => ({
  type: ACTIONS.USERS.FETCH,
  payload: { partnerId: partnerId, collaboratorId: collaboratorId }
});

export const getUsersSuccess = (users: any[], nextPage?: string) => ({
  type: ACTIONS.USERS.SUCCESS,
  payload: { users: users, nextPage: nextPage }
});

export const getUsersFail = (error: object) => ({ type: ACTIONS.USERS.FAIL, payload: { error } });

export const getUsersNextPageFetch = (nextPage: string) => ({
  type: ACTIONS.USERS.NEXT_PAGE,
  payload: { nextPage }
});
