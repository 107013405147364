import React, { useEffect, useState } from 'react';
import { Redirect } from 'react-router-dom';
import { Container, Card, CardContent, Typography, CardActions } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { useKeycloak } from '@react-keycloak/web';

import { Button } from '@wdynamo/common/lib/components';
import { Analytics } from '@wdynamo/common/lib/services';
// eslint-disable-next-line no-unused-vars
import { IAuthProps } from './models';

import { uris } from '../../siteMap';

interface LoginFunctionalComponentProps {
  [key: string]: any;
  authStore: IAuthProps;
  updateCurrentUserInfo(fieldKey: string, value: any): void;
  className?: string;
}

const CleanLoginComponent: React.FC<LoginFunctionalComponentProps> = (props: LoginFunctionalComponentProps) => {
  const analytics = Analytics();
  const { t } = useTranslation();
  const { keycloak } = useKeycloak();
  const [username, setUsername] = useState(null);
  const { authStore } = props;
  const [shouldRedirect, setShouldRedirect] = useState(false);

  useEffect(() => {
    analytics.pageview('login');
  }, []);

  useEffect(() => {
    if (keycloak?.authenticated) {
      keycloak?.loadUserInfo().then((userInfo: any) => setUsername(userInfo.preferred_username));
    }
    if (keycloak?.authenticated && authStore.currentUserInfo.userJustLoggedIn) {
      setShouldRedirect(true);
      props.updateCurrentUserInfo('userJustLoggedIn', false);
    }
  }, [keycloak?.authenticated]);

  const handleLogin = () => {
    props.updateCurrentUserInfo('userJustLoggedIn', true);
    keycloak?.login();
  };

  const renderLogin = () => <Button onClick={handleLogin}>{t('AUTH.LOGIN.LOGIN')}</Button>;

  const renderLogout = () => <Button onClick={keycloak?.logout}>{t('AUTH.LOGIN.LOGOUT')}</Button>;

  return (
    <>
      {shouldRedirect ? (
        <Redirect to={uris.home.uri} />
      ) : (
        <Container className={props.className || 'login-card'} maxWidth='sm'>
          <Card variant='outlined'>
            <CardContent className='auth-card-content'>
              <Typography color='textSecondary' gutterBottom variant='h5' component='h2'>
                {keycloak?.authenticated
                  ? t('AUTH.LOGIN.LOGOUT_CONFIRMATION', { username: username })
                  : t('AUTH.LOGIN.TITLE')}
              </Typography>
            </CardContent>
            <CardActions className='auth-card-buttons'>
              {keycloak?.authenticated ? renderLogout() : renderLogin()}
            </CardActions>
          </Card>
        </Container>
      )}
    </>
  );
};

export const LoginComponent = styled(({ ...props }) => <CleanLoginComponent {...props} />)`
  margin-top: 100px;

  .auth-card-content {
    text-align: center;
  }
  .auth-card-buttons {
    justify-content: center;
  }
`;
