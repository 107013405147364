export type actionType = {
  type: string;
  error?: boolean;
  payload?: any;
};

export interface IBaseStateProps {
  loading: boolean;
  error: boolean;
  data?: any;
}

export const ACTIONS = {
  EXAMPLE: {
    FETCH: 'EXAMPLE_GET',
    SUCCESS: 'EXAMPLE_SUCCESS',
    FAIL: 'EXAMPLE_FAIL'
  },
  AUTH: {
    UPDATE_CURRENT_USER_INFO: 'AUTH_UPDATE_CURRENT_USER_INFO'
  },
  ADD_USER: {
    GET_ROLES: {
      FETCH: 'ADD_USER_GET_ROLES_FETCH',
      SUCCESS: 'ADD_USER_GET_ROLES_SUCCESS',
      FAIL: 'ADD_USER_GET_ROLES_FAIL'
    },
    ADD: {
      FETCH: 'ADD_USER_ADD_FETCH',
      SUCCESS: 'ADD_USER_ADD_SUCCESS',
      FAIL: 'ADD_USER_ADD_FAIL'
    }
  },
  PRODUCTS: {
    LIST: {
      FETCH: 'PRODUCTS_LIST_FETCH',
      SUCCESS: 'PRODUCTS_LIST_SUCCESS',
      FAIL: 'PRODUCTS_LIST_FAIL'
    },
    CHOICE: 'PRODUCTS_CHOICE'
  },
  CHECKOUT: {
    RESET: 'CHECKOUT_RESET',
    FIND_DOC_NUMBER: {
      FETCH: 'CHECKOUT_FIND_DOC_NUMBER_FETCH',
      SUCCESS: 'CHECKOUT_FIND_DOC_NUMBER_SUCCESS',
      FAIL: 'CHECKOUT_FIND_DOC_NUMBER_FAIL',
      RESET: 'CHECKOUT_FIND_DOC_NUMBER_RESET'
    },
    UPDATE_CLIENT_INFO: 'CHECKOUT_UPDATE_CLIENT_INFO',
    UPDATE_ADDRESS_INFO: 'CHECKOUT_UPDATE_ADDRESS_INFO',
    UPDATE_PAYMENT_INFO: 'CHECKOUT_UPDATE_PAYMENT_INFO',
    ZIPCODE: {
      FETCH: 'CHECKOUT_ZIPCODE_FETCH',
      SUCCESS: 'CHECKOUT_ZIPCODE_SUCCESS',
      FAIL: 'CHECKOUT_ZIPCODE_FAIL'
    },
    CONFIRMATION: {
      FETCH: 'CHECKOUT_CONFIRMATION_FETCH',
      SUCCESS: 'CHECKOUT_CONFIRMATION_SUCCESS',
      FAIL: 'CHECKOUT_CONFIRMATION_FAIL'
    },
    PAYMENT_URL: {
      FETCH: 'CHECKOUT_PAYMENT_URL_FETCH',
      SUCCESS: 'CHECKOUT_PAYMENT_URL_SUCCESS',
      FAIL: 'CHECKOUT_PAYMENT_URL_FAIL'
    }
  },
  MY_SALES: {
    TABLE: {
      FETCH: 'MY_SALES_TABLE_FETCH',
      SUCCESS: 'MY_SALES_TABLE_SUCCESS',
      FAIL: 'MY_SALES_TABLE_FAIL',
      NEXT_PAGE: 'MY_SALES_TABLE_NEXT_PAGE_FETCH'
    },
    DASHBOARD: {
      FETCH: 'MY_SALES_DASHBOARD_FETCH',
      SUCCESS: 'MY_SALES_DASHBOARD_SUCCESS',
      FAIL: 'MY_SALES_DASHBOARD_FAIL'
    }
  },
  USERS: {
    FETCH: 'USERS_FETCH',
    SUCCESS: 'USERS_SUCCESS',
    FAIL: 'USERS_FAIL',
    NEXT_PAGE: 'USERS_NEXT_PAGE_FETCH'
  },
  REPORTS: {
    ORDERS_GROUPED_BY_DATE: {
      FETCH: 'REPORTS_ORDERS_GROUPED_BY_DATE_FETCH',
      SUCCESS: 'REPORTS_ORDERS_GROUPED_BY_DATE_SUCCESS',
      FAIL: 'REPORTS_ORDERS_GROUPED_BY_DATE_FAIL'
    },
    PRODUCTS: { FETCH: 'REPORTS_PRODUCTS_FETCH', SUCCESS: 'REPORTS_PRODUCTS_SUCCESS', FAIL: 'REPORTS_PRODUCTS_FAIL' },
    SALESTEAMS: {
      FETCH: 'REPORTS_SALESTEAMS_FETCH',
      SUCCESS: 'REPORTS_SALESTEAMS_SUCCESS',
      FAIL: 'REPORTS_SALESTEAMS_FAIL'
    },
    SALESMEN: { FETCH: 'REPORTS_SALESMEN_FETCH', SUCCESS: 'REPORTS_SALESMEN_SUCCESS', FAIL: 'REPORTS_SALESMEN_FAIL' },
    ORDERS: {
      FETCH: 'REPORTS_ORDERS_FETCH',
      SUCCESS: 'REPORTS_ORDERS_SUCCESS',
      FAIL: 'REPORTS_ORDERS_FAIL',
      NEXT_PAGE: 'REPORTS_ORDERS_NEXT_PAGE',
      TO_EXPORT: {
        FETCH: 'REPORTS_ORDERS_TO_EXPORT_FETCH',
        SUCCESS: 'REPORTS_ORDERS_TO_EXPORT_SUCCESS'
      }
    }
  }
};
