import { connect } from 'react-redux';
import { AddUserComponent } from './view';
import { getRolesFetch, addUserFetch } from './actions';
// eslint-disable-next-line no-unused-vars
import { RootState } from '../../store';

const mapStateToProps = (state: RootState) => ({
  addUserStore: state.addUserState
});
const mapDispatchToProps = { getRolesFetch, addUserFetch };

export const AddUser = connect(mapStateToProps, mapDispatchToProps)(AddUserComponent);
export { addUserSaga } from './sagas';
export { addUserReducer } from './reducers';
export type { IBaseStore } from './models';
