import React, { useState, useEffect } from 'react';
import {
  MenuItem,
  Paper,
  Grid,
  Container,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from '@material-ui/core';

import { Button, Input } from '@wdynamo/common';
import { Analytics } from '@wdynamo/common/lib/services';

import { useKeycloak } from '@react-keycloak/web';
import { Redirect } from 'react-router-dom';
import { uris } from '../../siteMap';

const currencies = [
  {
    value: 'USD',
    label: '$'
  },
  {
    value: 'EUR',
    label: '€'
  },
  {
    value: 'BTC',
    label: '฿'
  },
  {
    value: 'JPY',
    label: '¥'
  }
];

export const ExampleComponent = (props: any) => {
  const { exampleStore } = props;
  const getExampleData = () => props.exampleFetch();

  useEffect(() => {
    Analytics().pageview('example');
  }, []);

  useEffect(() => {
    if (Object.keys(exampleStore.data).length === 0 && !exampleStore.loading) getExampleData();
  }, [exampleStore]);

  const [selectedCurrency, setSelectedCurrency] = useState(currencies[0].value);

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      console.log('enter press here! ');
    }
  };

  const renderPrices = () => {
    if (Object.keys(exampleStore.data).length) {
      return (
        <>
          <h5>Bitcoin prices</h5>
          <TableContainer component={Paper}>
            <Table aria-label='simple table'>
              <TableHead>
                <TableRow>
                  <TableCell>Currency</TableCell>
                  <TableCell align='right'>Rate</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {Object.keys(exampleStore.data.bpi).map((criptoCurrency: string) => {
                  const tableValues: any = exampleStore.data.bpi[criptoCurrency];
                  return (
                    <TableRow key={tableValues.code}>
                      <TableCell component='th' scope='row'>
                        {tableValues.code}
                      </TableCell>
                      <TableCell align='right'>{tableValues.rate}</TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      );
    } else return <></>;
  };

  return (
    <Container maxWidth='sm'>
      <Grid item xs={12}>
        <Button onClick={getExampleData}>Actualizar precios</Button>
      </Grid>
      <br></br>
      {renderPrices()}
      <br></br>
      <Grid item xs={12}>
        <Input
          label={'Label'}
          helperText={'Helper Text'}
          error={true}
          onChange={(currency: string): void => {
            Analytics().trackEvent('home-buttons', 'select-currency', undefined, currency);
            setSelectedCurrency(currency);
          }}
          onKeyPress={(event: React.KeyboardEvent<HTMLInputElement>): void => handleKeyPress(event)}
          select={true}
          defaultValue={selectedCurrency}
        >
          {currencies.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </Input>
      </Grid>
    </Container>
  );
};

export function Home() {
  const { keycloak } = useKeycloak();
  return keycloak?.authenticated ? <Redirect to={uris.products.uri} /> : <Redirect to={uris.login.uri} />;
}
