// eslint-disable-next-line no-unused-vars
import { SiteMap } from '@wdynamo/common';

import { uris } from './siteMap';
import { Home } from './views/Example';
import { Login } from './views/Auth';
import { AddUser } from './views/AddUser';
import { Products } from './views/Products';
import { MySales } from './views/MySales';
import { Checkout } from './views/Checkout';
import { Users } from './views/Users';
import { Reports } from './views/Reports';

import activeProductsImage from './assets/img/header/icon_nav_product_active.svg';
import productsImage from './assets/img/header/icon_nav_product.svg';
import activeSalesImage from './assets/img/header/icon_nav_sales_active.svg';
import salesImage from './assets/img/header/icon_nav_sales.svg';
import activeUsersImage from './assets/img/header/icon_nav_users_active.svg';
import usersImage from './assets/img/header/icon_nav_users.svg';
import reportsImage from './assets/img/header/icon_nav_reports.svg';
import activeReportsImage from './assets/img/header/icon_nav_reports_active.svg';

type AppConfig = {
  apiUrl: string;
  loggedSitemap: SiteMap[];
  anonymousSiteMap: SiteMap[];
};

const loggedSitemap: SiteMap[] = [
  {
    ...uris.products,
    component: Products,
    exact: true,
    roles: process.env.REACT_APP_KEYCLOAK_SALES_ROL?.split(','),
    hideFromHeader: true,
    displayAsFastLink: true,
    icon: {
      active: activeProductsImage,
      default: productsImage
    }
  },
  { ...uris.add_user, component: AddUser, roles: process.env.REACT_APP_KEYCLOAK_ADD_USER_ROL?.split(',') },
  {
    ...uris.mySales,
    component: MySales,
    exact: true,
    roles: process.env.REACT_APP_KEYCLOAK_SALES_ROL?.split(','),
    hideFromHeader: true,
    displayAsFastLink: true,
    icon: {
      active: activeSalesImage,
      default: salesImage
    }
  },
  { ...uris.login, label: 'Logout', component: Login, exact: true },
  { ...uris.home, component: Home, exact: true, hideFromHeader: true },
  { ...uris.checkout, component: Checkout, exact: true, hideFromHeader: true },
  {
    ...uris.users,
    component: Users,
    exact: true,
    roles: process.env.REACT_APP_KEYCLOAK_ADD_USER_ROL?.split(','),
    hideFromHeader: true,
    icon: {
      active: activeUsersImage,
      default: usersImage
    }
  },
  {
    ...uris.reports,
    component: Reports,
    exact: true,
    roles: process.env.REACT_APP_KEYCLOAK_REPORTS_ROLES?.split(','),
    hideFromHeader: true,
    displayAsFastLink: false,
    icon: {
      active: activeReportsImage,
      default: reportsImage
    }
  }
];

const anonymousSiteMap: SiteMap[] = [
  { ...uris.home, component: Home, exact: true, hideFromHeader: true },
  { ...uris.login, component: Login }
];

const Config: AppConfig = {
  apiUrl: process.env.REACT_APP_PUBLIC_URL as string,
  loggedSitemap,
  anonymousSiteMap
};

export default Config;
