import React, { useEffect, useState, useRef } from 'react';
import { Container, Grid, MenuItem } from '@material-ui/core';
import { TextPoint } from '@wdynamo/common';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { useKeycloak } from '@react-keycloak/web';

import { Button, Input, CustomAlert, AlertSeverities, CancelModal, SuccessModal } from '@wdynamo/common/lib/components';
import { Analytics } from '@wdynamo/common/lib/services';

import CancelIllustration from '../../assets/img/CancelIllustration.jpg';
import SuccessIllustration from '../../assets/img/SuccessIllustration.jpg';

// eslint-disable-next-line no-unused-vars
import { IAddUserForm, AddUserForm, IBaseStore } from './models';

interface AddUserFunctionalComponentProps {
  [key: string]: any;
  addUserStore: IBaseStore;
  getRolesFetch(): void;
  addUserFetch(data: AddUserForm): void;
  className?: string;
}

const initialAddUserForm: IAddUserForm = {
  email: { value: '', error: false, isRequired: true },
  groups: { value: [], error: false, isRequired: true },
  username: { value: '', error: false, isRequired: true },
  collaboratorId: { value: '', error: false, isRequired: true }
};

const usePrevious = (value: IBaseStore): IBaseStore => {
  const ref = useRef<IBaseStore>();
  useEffect(() => {
    ref.current = value;
  }, [value]);
  return ref.current as IBaseStore;
};

const CleanAddUserComponent: React.FC<AddUserFunctionalComponentProps> = (props: AddUserFunctionalComponentProps) => {
  const analytics = Analytics();
  const { keycloak } = useKeycloak();
  const { t } = useTranslation();
  const [currentUserCollaboratorId, setCurrentUserCollaboratorId] = useState<string[]>(['']);
  const { addUserStore } = props;
  const prevAddUserStore = usePrevious(addUserStore);
  const [addUserForm, setAddUserForm] = useState<IAddUserForm>(initialAddUserForm);
  const [addUserButtonDisabled, setAddUserButtonDisabled] = useState(true);
  const [showCancelModal, setShowCancelModal] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);

  useEffect(() => {
    analytics.pageview('add-user');
  }, []);

  useEffect(() => {
    if (keycloak?.authenticated) {
      setTimeout(() => props.getRolesFetch());
      keycloak?.loadUserInfo().then((userInfo: any) => setCurrentUserCollaboratorId([userInfo.collaborator_id]));
    }
  }, [keycloak?.authenticated]);

  useEffect(() => {
    setAddUserButtonDisabled(!validateFormFields() || addUserStore.addUser.loading);
    if (prevAddUserStore?.addUser.loading && !addUserStore!.addUser.loading && !addUserStore!.addUser.error) {
      setAddUserForm(initialAddUserForm);
      toggleSuccessModal();
    }
  }, [addUserForm, addUserStore]);

  const validateFormFields = () => {
    let isFormValid: boolean = true;
    Object.keys(addUserForm).forEach((item) => {
      const inputField = addUserForm[item];
      isFormValid =
        isFormValid &&
        !inputField.error &&
        !!((inputField.isRequired && inputField.value.length) || !inputField.isRequired);
    });
    return isFormValid;
  };

  const handleOnChange = (fieldToUpdate: string, value: string) =>
    setAddUserForm({ ...addUserForm, [fieldToUpdate]: { ...addUserForm[fieldToUpdate], value: value, error: false } });

  const selectHandleOnChange = (fieldToUpdate: string, value: string) =>
    setAddUserForm({
      ...addUserForm,
      [fieldToUpdate]: { ...addUserForm[fieldToUpdate], value: [value], error: false }
    });

  const addUser = () => {
    if (validateFormFields()) {
      const data: AddUserForm = {
        groups: addUserForm.groups.value as string[],
        email: addUserForm.email.value as string,
        username: addUserForm.username.value as string,
        collaborator_id: addUserForm.collaboratorId.value as string,
        redirect_uri: window.location.origin,
        parent_collaborator_ids: addUserForm.groups.value.includes('334fe6fd-2cdf-4a87-85c8-e548985cf673')
          ? undefined
          : (currentUserCollaboratorId as string[])
      };
      analytics.trackEvent('add-user', 'add-new-user', undefined, data.email);
      props.addUserFetch(data);
    }
  };

  const selectOptions = addUserStore.roles.data?.manageableGroups ? addUserStore.roles.data.manageableGroups : [];

  const toggleCancelModal = () => {
    setShowCancelModal(!showCancelModal);
  };
  const toggleSuccessModal = () => {
    setShowSuccessModal(!showSuccessModal);
  };

  const handleCancelModalConfirm = () => {
    setAddUserForm(initialAddUserForm);
    toggleCancelModal();
  };
  const handleSuccessModalConfirm = () => {
    setAddUserForm(initialAddUserForm);
    toggleSuccessModal();
  };

  const renderModal = () => (
    <>
      <CancelModal
        open={showCancelModal}
        handleClose={toggleCancelModal}
        onClickConfirm={handleCancelModalConfirm}
        onClickCancel={toggleCancelModal}
        messages={{
          TITLE: t('COMMONS.MODALS.CANCEL.TITLE'),
          CANCEL_BUTTON: t('COMMONS.MODALS.CANCEL.CANCEL'),
          CONTINUE_BUTTON: t('COMMONS.MODALS.CANCEL.CONTINUE')
        }}
        img={CancelIllustration}
      />
      <SuccessModal
        open={showSuccessModal}
        handleClose={toggleSuccessModal}
        onClickConfirm={handleSuccessModalConfirm}
        description={t('ADD_USER.SUCCESS.TEXT')}
        img={SuccessIllustration}
        messages={{
          TITLE: t('ADD_USER.SUCCESS.TITLE'),
          CONTINUE_BUTTON: t('COMMONS.BUTTONS.CONTINUE')
        }}
      />
    </>
  );

  return (
    <Container className={props.className || 'add-user'} maxWidth='sm'>
      <h2>
        {t('ADD_USER.TITLE')}
        <TextPoint />
      </h2>
      <div className='add-user-form'>
        <Grid direction='row' container spacing={2}>
          <Grid item xs={12} sm={6} className='form-input'>
            <Input
              label={t('ADD_USER.EMAIL')}
              autoComplete={true}
              defaultValue={addUserForm.email.value as string}
              error={addUserForm.email.error}
              onChange={(value: string): void => handleOnChange('email', value)}
              testId='email'
              type='email'
              required={true}
            />
          </Grid>
          <Grid item xs={12} sm={6} className='form-input'>
            <Input
              label={t('ADD_USER.GROUP')}
              error={addUserForm.groups.error}
              defaultValue={addUserForm.groups.value as string[]}
              onChange={(value: string): void => selectHandleOnChange('groups', value)}
              testId='groups'
              required={true}
              select={true}
            >
              {selectOptions.map((rol) => (
                <MenuItem key={rol.id} value={rol.id}>
                  {rol.name}
                </MenuItem>
              ))}
            </Input>
          </Grid>
          <Grid item xs={12} sm={6} className='form-input'>
            <Input
              label={t('ADD_USER.USERNAME')}
              defaultValue={addUserForm.username.value as string}
              error={addUserForm.username.error}
              onChange={(value: string): void => handleOnChange('username', value)}
              testId='username'
              required={true}
            />
          </Grid>
          <Grid item xs={12} sm={6} className='form-input'>
            <Input
              label={t('ADD_USER.COLLABORATOR_ID')}
              defaultValue={addUserForm.collaboratorId.value as string}
              error={addUserForm.collaboratorId.error}
              onChange={(value: string): void => handleOnChange('collaboratorId', value)}
              testId='collaboratorId'
              required={true}
            />
          </Grid>
          {addUserStore.addUser.message &&
            addUserStore.addUser.message.map((error: string, index: number) => (
              <Grid key={index} item xs={12} sm={6} className='form-input'>
                <CustomAlert key={index} severity={AlertSeverities.ERROR} className='alert'>
                  {error}
                </CustomAlert>
              </Grid>
            ))}
        </Grid>
        <Grid className='add-user-button' direction='row' justify={'center'} container spacing={2}>
          <Grid item>
            <Button className='cancel' onClick={toggleCancelModal}>
              {t('ADD_USER.CANCEL')}
            </Button>
          </Grid>
          <Grid item>
            <Button className='add' onClick={addUser} disabled={addUserButtonDisabled}>
              {t('ADD_USER.ADD')}
            </Button>
          </Grid>
        </Grid>
        {renderModal()}
      </div>
    </Container>
  );
};

export const AddUserComponent = styled(({ ...props }) => <CleanAddUserComponent {...props} />)`
  .add-user-form {
    .add-user-button {
      margin-top: 40px;
      .cancel,
      .add {
        padding: 10px 50px;
        border-radius: 25px;
        text-transform: none;
        font-size: 16px;
      }
      .cancel {
        background-color: transparent;
        box-shadow: none;
        color: var(--color-grey-text);
        border: 1px solid transparent;
        &:hover {
          border: 1px solid var(--color-grey-border);
        }
      }
      .add {
        background-color: var(--color-orange);
        color: var(--color-white);
      }
    }
  }
  .alert {
    margin-bottom: 20px;
  }
`;
