import axios from 'axios';
// eslint-disable-next-line no-unused-vars
import { AddUserForm } from './models';

const Api = () => {
  const getRolesFetch = (): Promise<unknown> => axios.get(`${process.env.REACT_APP_ADD_USER_BASE_URL}me`);

  const addUserFetch = (addUserForm: AddUserForm): Promise<unknown> =>
    axios.post(`${process.env.REACT_APP_ADD_USER_BASE_URL}users`, addUserForm);

  return { getRolesFetch, addUserFetch };
};

export default Api;
