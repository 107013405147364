export interface IItemProps {}

export interface IMySalesTableProps {
  loading: boolean;
  error: boolean;
  nextPage?: string;
  items: object[];
}

export interface IMySalesDashboardProps {
  loading: boolean;
  error: boolean;
  items: object[];
}

export interface IMySalesProps {
  table: IMySalesTableProps;
  dashboard: IMySalesDashboardProps;
}

export interface ISoldItem {
  price: number;
  title: string;
  amount: number;
  shortDescription: string;
  description: string;
}

export interface ISoldItems {
  [itemCode: string]: ISoldItem;
}

export interface ISoldItemsByDate {
  [date: string]: object[];
}

export enum IChartPeriods {
  // eslint-disable-next-line no-unused-vars
  MONTHLY = 'monthly',
  // eslint-disable-next-line no-unused-vars
  WEEKLY = 'weekle',
  // eslint-disable-next-line no-unused-vars
  DAILY = 'daily'
}
