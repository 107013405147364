import { connect } from 'react-redux';
import { UsersComponent } from './view';
import { getUsersFetch, getUsersNextPageFetch } from './actions';
// eslint-disable-next-line no-unused-vars
import { RootState } from '../../store';

const mapStateToProps = (state: RootState) => ({
  usersStore: state.usersState
});
const mapDispatchToProps = { getUsersFetch, getUsersNextPageFetch };

export const Users = connect(mapStateToProps, mapDispatchToProps)(UsersComponent);
export { usersSaga } from './sagas';
export { usersReducer } from './reducers';
export type { IUsersProps } from './models';
