import { createStore, combineReducers, applyMiddleware, compose } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';
import createSagaMiddleware from 'redux-saga';

import rootSaga from './saga';
// eslint-disable-next-line no-unused-vars
import { IBaseStateProps } from './views/ActionTypes';
import { exampleReducer } from './views/Example';
import { authReducer } from './views/Auth';
// eslint-disable-next-line no-unused-vars
import { addUserReducer, IBaseStore } from './views/AddUser';
import { productsReducer } from './views/Products';
import { checkoutReducer } from './views/Checkout';
// eslint-disable-next-line no-unused-vars
import { mySalesReducer, IMySalesProps } from './views/MySales';
// eslint-disable-next-line no-unused-vars
import { usersReducer, IUsersProps } from './views/Users';
// eslint-disable-next-line no-unused-vars
import { reportsReducer, IReportsProps } from './views/Reports';

const sagaMiddleware = createSagaMiddleware();

export interface IAppState {
  exampleState: IBaseStateProps;
  authState: IBaseStore;
  addUserState: IBaseStore;
  productsReducer: IBaseStateProps;
  checkoutReducer: IBaseStateProps;
  mySalesReducer: IMySalesProps;
  usersReducer: IUsersProps;
  reportsReducer: IReportsProps;
}

const persistConfig = {
  key: 'example',
  storage: storage,
  stateReconciler: autoMergeLevel2,
  whitelist: ['data'],
  blacklist: ['loading', 'error']
};
const persistAuthConfig = {
  key: 'authReducer',
  storage: storage,
  whitelist: ['currentUserInfo']
};
const reducers = combineReducers({
  exampleState: persistReducer(persistConfig, exampleReducer),
  authState: persistReducer(persistAuthConfig, authReducer),
  addUserState: addUserReducer,
  productsState: productsReducer,
  checkoutState: checkoutReducer,
  mySalesState: mySalesReducer,
  usersState: usersReducer,
  reportsState: reportsReducer
});

declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose;
  }
}

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(reducers, composeEnhancers(applyMiddleware(sagaMiddleware)));
const persistor = persistStore(store);
sagaMiddleware.run(rootSaga);

export const Store = store;
export const Persistor = persistor;
export type RootState = ReturnType<typeof reducers>;
