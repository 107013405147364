import { ACTIONS } from '../ActionTypes';

export const getOrdersGroupedByDateFetch = (from_date: string, to_date: string, include_children: boolean) => ({
  type: ACTIONS.REPORTS.ORDERS_GROUPED_BY_DATE.FETCH,
  payload: { from_date, to_date, include_children }
});
export const getOrdersGroupedByDateSuccess = (data: object) => ({
  type: ACTIONS.REPORTS.ORDERS_GROUPED_BY_DATE.SUCCESS,
  payload: { data }
});
export const getOrdersGroupedByDateFail = (error: object) => ({
  type: ACTIONS.REPORTS.ORDERS_GROUPED_BY_DATE.FAIL,
  payload: { error }
});

export const getTopProductsFetch = (
  from_date: string,
  to_date: string,
  include_children: boolean,
  sort: string,
  items_limit: number
) => ({
  type: ACTIONS.REPORTS.PRODUCTS.FETCH,
  payload: { from_date, to_date, include_children, sort, items_limit }
});
export const getTopProductsSuccess = (data: object) => ({ type: ACTIONS.REPORTS.PRODUCTS.SUCCESS, payload: { data } });
export const getTopProductsFail = (error: object) => ({ type: ACTIONS.REPORTS.PRODUCTS.FAIL, payload: { error } });

export const getTopSalesTeamsFetch = (
  from_date: string,
  to_date: string,
  include_children: boolean,
  sort: string,
  items_limit: number
) => ({
  type: ACTIONS.REPORTS.SALESTEAMS.FETCH,
  payload: { from_date, to_date, include_children, sort, items_limit }
});
export const getTopSalesTeamsSuccess = (data: object) => ({
  type: ACTIONS.REPORTS.SALESTEAMS.SUCCESS,
  payload: { data }
});
export const getTopSalesTeamsFail = (error: object) => ({ type: ACTIONS.REPORTS.SALESTEAMS.FAIL, payload: { error } });

export const getTopSalesmenFetch = (
  from_date: string,
  to_date: string,
  include_children: boolean,
  sort: string,
  items_limit: number
) => ({
  type: ACTIONS.REPORTS.SALESMEN.FETCH,
  payload: { from_date, to_date, include_children, sort, items_limit }
});
export const getTopSalesmenSuccess = (data: object) => ({ type: ACTIONS.REPORTS.SALESMEN.SUCCESS, payload: { data } });
export const getTopSalesmenFail = (error: object) => ({ type: ACTIONS.REPORTS.SALESMEN.FAIL, payload: { error } });

export const getOrdersFetch = (
  from_date: string,
  to_date: string,
  include_children: boolean,
  sort: string,
  pagelen?: number,
  order_status?: string
) => ({
  type: ACTIONS.REPORTS.ORDERS.FETCH,
  payload: { from_date, to_date, include_children, sort, pagelen, order_status }
});
export const getOrdersToExportFetch = (
  from_date: string,
  to_date: string,
  include_children: boolean,
  sort: string,
  pagelen?: number,
  order_status?: string
) => ({
  type: ACTIONS.REPORTS.ORDERS.TO_EXPORT.FETCH,
  payload: { from_date, to_date, include_children, sort, pagelen, order_status }
});
export const getOrdersSuccess = (data: object) => ({ type: ACTIONS.REPORTS.ORDERS.SUCCESS, payload: { data } });
export const getOrdersToExportSuccess = (data: object) => ({
  type: ACTIONS.REPORTS.ORDERS.TO_EXPORT.SUCCESS,
  payload: { data }
});
export const getOrdersFail = (error: object) => ({ type: ACTIONS.REPORTS.ORDERS.FAIL, payload: { error } });
export const getOrdersNextPageFetch = (nextPage: string) => ({
  type: ACTIONS.REPORTS.ORDERS.NEXT_PAGE,
  payload: { nextPage }
});
