import { ACTIONS } from '../ActionTypes';
// eslint-disable-next-line no-unused-vars
import { AddUserForm, IUserRoles } from './models';

// Get roles
export const getRolesFetch = () => ({ type: ACTIONS.ADD_USER.GET_ROLES.FETCH });

export const getRolesSuccess = (userGroups: IUserRoles) => ({
  type: ACTIONS.ADD_USER.GET_ROLES.SUCCESS,
  payload: { data: userGroups }
});

export const getRolesFail = (error: object) => ({ type: ACTIONS.ADD_USER.GET_ROLES.FAIL, payload: { data: error } });

// Add user
export const addUserFetch = (addUserForm: AddUserForm) => ({
  type: ACTIONS.ADD_USER.ADD.FETCH,
  payload: { data: addUserForm }
});

export const addUserSuccess = () => ({ type: ACTIONS.ADD_USER.ADD.SUCCESS });

export const addUserFail = (error: object) => ({ type: ACTIONS.ADD_USER.ADD.FAIL, payload: { error } });
