import axios from 'axios';

const Api = () => {
  //@TODO: The next url going to change to be a real url and not the query string params
  const getMySalesFetch = (nextPage?: string): Promise<unknown> =>
    nextPage
      ? axios.get(`${process.env.REACT_APP_GET_MY_SALES_URL}?${nextPage}`)
      : axios.get(`${process.env.REACT_APP_GET_MY_SALES_URL}`, {
          params: {
            pagelen: 10,
            sort: '-created_at',
            page: 1
          }
        });

  const getMySalesDashboardFetch = (): Promise<unknown> =>
    axios.get(`${process.env.REACT_APP_GET_MY_SALES_URL}`, {
      params: {
        pagelen: 100,
        sort: '-created_at',
        page: 1
      }
    });

  return { getMySalesFetch, getMySalesDashboardFetch };
};

export default Api;
