import { makeObjectData } from '@wdynamo/common/lib/utils';

// eslint-disable-next-line no-unused-vars
import { ACTIONS, actionType } from '../ActionTypes';
// eslint-disable-next-line no-unused-vars
import { IBaseStore } from './models';

const initialState: IBaseStore = {
  roles: {
    loading: false,
    error: false,
    data: undefined
  },
  addUser: {
    loading: false,
    error: false,
    message: undefined
  }
};

export const addUserReducer = (state = initialState, action: actionType) => {
  switch (action.type) {
    // Get roles
    case ACTIONS.ADD_USER.GET_ROLES.FETCH:
      return {
        ...state,
        roles: {
          ...state.roles,
          loading: true,
          error: false,
          data: undefined
        }
      };
    case ACTIONS.ADD_USER.GET_ROLES.SUCCESS:
      return {
        ...state,
        roles: {
          ...state.roles,
          loading: false,
          data: makeObjectData(action.payload.data)
        }
      };
    case ACTIONS.ADD_USER.GET_ROLES.FAIL:
      return {
        ...state,
        roles: {
          ...state.roles,
          loading: false,
          error: true
        }
      };

    // Add User
    case ACTIONS.ADD_USER.ADD.FETCH:
      return {
        ...state,
        addUser: {
          ...state.addUser,
          loading: true,
          error: false,
          message: undefined
        }
      };
    case ACTIONS.ADD_USER.ADD.SUCCESS:
      return {
        ...state,
        addUser: {
          ...state.addUser,
          loading: false,
          error: false
        }
      };
    case ACTIONS.ADD_USER.ADD.FAIL:
      return {
        ...state,
        addUser: {
          ...state.addUser,
          loading: false,
          error: true,
          message: action.payload.error.data.errors
        }
      };

    default:
      return state;
  }
};
