import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Grid } from '@material-ui/core';
import { Date, Price, CustomInfiniteScroll, Loading, Modal, TextPoint, CustomTooltip } from '@wdynamo/common';

import iconEntered from '../../../assets/img/icons_dynamo/icon_entered.svg';
import iconCheck from '../../../assets/img/icons_dynamo/icon_check.svg';
import iconTime from '../../../assets/img/icons_dynamo/icon_time.svg';
import iconTrash from '../../../assets/img/icons_dynamo/icon_trash.svg';
import iconExpired from '../../../assets/img/icons_dynamo/icon_expired.svg';

import modalCloseIcon from '../../../assets/img/icons_dynamo/icon_close.svg';
// eslint-disable-next-line no-unused-vars
import { IMySalesTableProps } from '../models';

interface IOrdersScrollProps {
  [key: string]: any;
  ordersData: IMySalesTableProps;
  firstLoading: boolean;
  paginate(nextPage: string): void;
  className?: string;
}

const CleanOrdersScroll: React.FC<IOrdersScrollProps> = (props: IOrdersScrollProps) => {
  const { ordersData } = props;
  const { t } = useTranslation();
  const [openModal, setOpenModal] = useState(false);
  const [isPaginating, setIsPaginating] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState<any>({});

  useEffect(() => {
    setIsPaginating(ordersData.loading);
  }, [ordersData.loading]);

  const handleChangePage = (): void => {
    if (ordersData.nextPage && !ordersData.loading && !isPaginating) {
      setIsPaginating(true);
      props.paginate(ordersData.nextPage);
    }
  };

  const renderModal = (): React.ReactNode => {
    let totalAmount: number = 0;
    selectedOrder.items?.forEach((item: any): void => (totalAmount += item.price.amount));

    return (
      <Modal
        closeIcon={<img src={modalCloseIcon} />}
        open={openModal}
        handleClose={() => {
          setSelectedOrder({});
          setOpenModal(false);
        }}
        className='order-modal padding'
        title={
          <h2>
            {t('MY_SALES.TABLE.SOLD_MODAL.TITLE')}
            <TextPoint />
          </h2>
        }
        body={
          <>
            {selectedOrder.items && (
              <Grid container>
                <Grid xs={12} sm={6} item className='item-container'>
                  <div className='item-label'>{t('MY_SALES.TABLE.SOLD_MODAL.ORDER')}</div>
                  <div className='item-value'>{selectedOrder.id}</div>
                </Grid>
                <Grid xs={12} sm={6} item className='item-container'>
                  <div className='item-label'>{t('MY_SALES.TABLE.SOLD_MODAL.CREATED_AT')}</div>
                  <Date date={selectedOrder.created_at} format={process.env.REACT_APP_DATE_FORMAT!} />
                </Grid>
                <Grid xs={12} sm={6} item className='item-container'>
                  <div className='item-label'>{t('MY_SALES.TABLE.SOLD_MODAL.PRODUCT')}</div>
                  {selectedOrder.items.map(
                    (item: any, index: number): React.ReactNode => (
                      <div key={index} className='item-value'>
                        {item.product.title}
                      </div>
                    )
                  )}
                </Grid>
                <Grid xs={12} sm={6} item className='item-container'>
                  <div className='item-label'>{t('MY_SALES.TABLE.SOLD_MODAL.AMOUNT')}</div>
                  <div className='item-value'>{selectedOrder.items.length}</div>
                </Grid>
                <Grid xs={12} sm={6} item className='item-container'>
                  <div className='item-label'>{t('MY_SALES.TABLE.SOLD_MODAL.EMAIL')}</div>
                  <div className='item-value'>{selectedOrder.customer.email}</div>
                </Grid>
                <Grid xs={12} sm={6} item className='item-container'>
                  <div className='item-label'>{selectedOrder.customer.identity.type.name}</div>
                  <div className='item-value'>{selectedOrder.customer.identity.value}</div>
                </Grid>
                <Grid xs={12} sm={6} item className='item-container'>
                  <div className='item-label'>{t('MY_SALES.TABLE.PURCHASE')}</div>

                  <div className='item-value'>
                    <CustomTooltip
                      title={getPaymentStatus(selectedOrder.current_status?.status)}
                      arrow
                      placement='bottom'
                    >
                      {getStatusIcon(selectedOrder.current_status?.status)}
                    </CustomTooltip>
                  </div>
                </Grid>
                <Grid xs={12} sm={6} item className='item-container'>
                  {selectedOrder.current_status.reasons.length == 0 ? (
                    <>
                      <div className='item-label'>{t('MY_SALES.TABLE.PAYMENT')}</div>
                      <div className='item-value'>
                        <CustomTooltip
                          title={getPaymentStatus(selectedOrder.payment_current_status?.status)}
                          arrow
                          placement='bottom'
                        >
                          {getStatusIcon(selectedOrder.payment_current_status?.status)}
                        </CustomTooltip>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className='item-label'>{t('MY_SALES.TABLE.REASON')}</div>
                      <div className='item-value'>
                        {selectedOrder.current_status?.reasons.map((reason: any) => (
                          <p key={reason.id}>{reason}</p>
                        ))}
                      </div>
                    </>
                  )}
                </Grid>
                <Grid xs={12} sm={6} item className='price'>
                  <Price amount={totalAmount} />
                </Grid>
              </Grid>
            )}
          </>
        }
      />
    );
  };

  const getStatusIcon = (status: string): React.ReactElement<HTMLImageElement> => {
    switch (status) {
      case 'DRAFT':
      case 'ENTERED':
        return <img src={iconEntered} />;
      case 'APPROVED':
        return <img src={iconCheck} />;
      case 'PENDING':
        return <img src={iconTime} />;
      case 'EXPIRED':
        return <img src={iconExpired} />;
      case 'DECLINED':
        return <img src={iconTrash} />;
      default:
        return <img src={iconTime} />;
    }
  };

  const getPaymentStatus = (status: string): string => {
    if (status == undefined) return t('MY_SALES.TABLE.TOOLTIPS.ENTERED');
    return t(`MY_SALES.TABLE.TOOLTIPS.${status}`);
  };
  const renderItemsTable = (): React.ReactNode => {
    const { loading, items, nextPage } = ordersData;
    if (!items.length && loading) return <Loading />;
    else if (!items.length) return <></>;
    else
      return (
        <Grid container className='my-sales-table'>
          <Grid item xs={12} className='header'>
            <Grid container>
              <Grid item sm={1}>
                {t('MY_SALES.TABLE.ORDER')}
              </Grid>
              <Grid item sm={2}>
                {t('MY_SALES.TABLE.DATE')}
              </Grid>
              <Grid item sm={3}>
                {t('MY_SALES.TABLE.EMAIL')}
              </Grid>
              <Grid item sm={2}>
                {t('MY_SALES.TABLE.PURCHASE')}
              </Grid>
              <Grid item sm={2}>
                {t('MY_SALES.TABLE.PAYMENT')}
              </Grid>
              <Grid item sm={2}></Grid>
            </Grid>
          </Grid>

          <Grid item xs={12} className='content'>
            <CustomInfiniteScroll nextUrl={nextPage} paginate={handleChangePage} firstLoading={props.firstLoading}>
              {items.map((order: any) => (
                <Grid
                  container
                  key={order.id}
                  className='sale'
                  onClick={() => {
                    setSelectedOrder(order);
                    setOpenModal(true);
                  }}
                >
                  <Grid item xs={6} sm={1}>
                    {order.id}
                  </Grid>
                  <Grid item xs={6} sm={2}>
                    <Date date={order.created_at} format={process.env.REACT_APP_DATE_FORMAT!} />
                  </Grid>
                  <Grid item xs={6} sm={3}>
                    {order.customer.email}
                  </Grid>
                  <Grid item xs={6} sm={2}>
                    <CustomTooltip title={getPaymentStatus(order.current_status?.status)} arrow placement='bottom'>
                      {getStatusIcon(order.current_status?.status)}
                    </CustomTooltip>
                  </Grid>
                  <Grid item xs={6} sm={2}>
                    <CustomTooltip
                      title={getPaymentStatus(order.payment_current_status?.status)}
                      arrow
                      placement='bottom'
                    >
                      {getStatusIcon(order.payment_current_status?.status)}
                    </CustomTooltip>
                  </Grid>
                  <Grid item xs={6} sm={2} className='price'>
                    {order.items.map((boughtItem: any, index: number) => (
                      <div key={index}>
                        <Price amount={boughtItem.price.amount} />
                      </div>
                    ))}
                  </Grid>
                </Grid>
              ))}
            </CustomInfiniteScroll>
          </Grid>
        </Grid>
      );
  };

  return (
    <div className={props.className}>
      <h3>
        {t('MY_SALES.TABLE.TITLE')}
        <TextPoint />
      </h3>
      {renderItemsTable()}
      {renderModal()}
    </div>
  );
};

export const OrdersScroll = styled(({ ...props }) => <CleanOrdersScroll {...props} />)`
  .my-sales-table {
    .header {
      border: none;
      div {
        padding: 5px 0;
        text-align: left;
        font-weight: bold;
      }
    }
    .content {
      .sale {
        padding: 10px 0;
        border-bottom: 1px solid var(--color-grey-border);
        &:last-child {
          border: none;
        }
        .price {
          text-align: right;
          font-size: 25px;
          color: var(--color-black);
        }
      }
    }
  }
`;
