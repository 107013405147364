export const uris = {
  home: {
    uri: '/',
    label: 'Home'
  },
  example: {
    uri: '/example',
    label: 'Ejemplo'
  },
  products: {
    uri: '/products',
    label: 'Productos'
  },
  login: {
    uri: '/login',
    label: 'Login'
  },
  add_user: {
    uri: '/add-user',
    label: 'Agregar usuario'
  },
  checkout: {
    uri: '/checkout',
    label: 'Realizar compra'
  },
  mySales: {
    uri: '/my-sales',
    label: 'Mis ventas'
  },
  users: {
    uri: '/users',
    label: 'Usuarios'
  },
  reports: {
    uri: '/reports',
    label: 'Reportes'
  }
};
