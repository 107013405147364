import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useKeycloak } from '@react-keycloak/web';
import { CustomTabs } from '@wdynamo/common';
import { MostSoldItems } from './components/MostSoldItems';
import { TopSalesTeams } from './components/TopSalesTeams';
import { TopSalesmen } from './components/TopSalesmen';

interface IReportsTabProps {
  products: any;
  salesTeams: any;
  salesmen: any;
  getTopProductsFetch(
    from_date: string,
    to_date: string,
    include_children: boolean,
    sort: string,
    items_limit: number
  ): void;
  getTopSalesTeamsFetch(
    from_date: string,
    to_date: string,
    include_children: boolean,
    sort: string,
    items_limit: number
  ): void;
  getTopSalesmenFetch(
    from_date: string,
    to_date: string,
    include_children: boolean,
    sort: string,
    items_limit: number
  ): void;
  className?: string;
  [key: string]: any;
}

const CleanReportsTabs: React.FC<IReportsTabProps> = (props: IReportsTabProps) => {
  const { t } = useTranslation();
  const { keycloak } = useKeycloak();
  const { products, salesTeams, salesmen } = props;
  const [value, setValue] = useState(0);

  const initialTabsHeaders = [
    {
      index: 0,
      label: t('REPORTS.TABS.TOP_PRODUCTS'),
      roles: process.env.REACT_APP_KEYCLOAK_REPORTS_TAB_PRODUCTS_ROLES?.split(',')
    },

    {
      index: 1,
      label: t('REPORTS.TABS.TOP_SALESMEN.TITLE'),
      roles: process.env.REACT_APP_KEYCLOAK_REPORTS_TAB_SALESMEN_ROLES?.split(',')
    },
    {
      index: 2,
      label: t('REPORTS.TABS.TOP_SALESTEAMS.TITLE'),
      roles: process.env.REACT_APP_KEYCLOAK_REPORTS_TAB_SALESTEAMS_ROLES?.split(',')
    }
  ];

  const [tabsHeaders, setTabsHeaders] = useState(initialTabsHeaders);

  const tabsPanels = [
    { index: 0, content: <MostSoldItems products={products} getTopProductsFetch={props.getTopProductsFetch} /> },

    { index: 1, content: <TopSalesmen salesmen={salesmen} getTopSalesmenFetch={props.getTopSalesmenFetch} /> },
    {
      index: 2,
      content: <TopSalesTeams salesTeams={salesTeams} getTopSalesTeamsFetch={props.getTopSalesTeamsFetch} />
    }
  ];

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };

  const checkIfRoleInRoles = (roles: string[]) => {
    let isInRoles: boolean = false;
    roles.forEach((tabRol: string) => {
      isInRoles = !!(isInRoles || keycloak?.realmAccess?.roles.includes(tabRol));
    });
    return isInRoles;
  };

  useEffect(() => {
    if (keycloak?.authenticated) {
      setTabsHeaders(tabsHeaders.filter((tabHeader) => checkIfRoleInRoles(tabHeader.roles!)));
    }
  }, [keycloak?.authenticated]);

  return <CustomTabs tabHeaders={tabsHeaders} tabPanels={tabsPanels} value={value} handleChange={handleChange} />;
};

export const ReportsTabs = (props: IReportsTabProps) => <CleanReportsTabs {...props} />;
