import React, { useState, useEffect } from 'react';
import { StylesProvider } from '@material-ui/styles';
import { BrowserRouter as Router, Route, Link, Switch } from 'react-router-dom';
import { useKeycloak } from '@react-keycloak/web';
import styled from 'styled-components';
import { Grid } from '@material-ui/core';

// eslint-disable-next-line no-unused-vars
import { SiteMap, Header, Footer } from '@wdynamo/common';

import HttpService from './services/HttpService';
import Config from './config';
import { uris } from './siteMap';
import DynamoLogo from './assets/img/LogoDynamo.png';

import './App.scss';

interface AppProps {
  className?: string;
  props?: any;
}

const CleanApp: React.FC<AppProps> = (options: AppProps) => {
  const [username, setUsername] = useState(null);
  const { keycloak } = useKeycloak();

  useEffect(() => {
    if (keycloak?.token) HttpService.setAuthToken(keycloak?.token);
  }, [keycloak?.token]);

  useEffect(() => {
    if (keycloak?.authenticated)
      keycloak?.loadUserInfo().then((userInfo: any) => setUsername(userInfo.preferred_username));
  }, [keycloak?.authenticated]);

  const preventDefault = (event: React.SyntheticEvent) => event.preventDefault();

  const checkIfRoleInRoles = (roles: string[]) => {
    let isInRoles: boolean = false;
    roles.forEach((routeRol: string) => {
      isInRoles = !!(isInRoles || keycloak?.realmAccess?.roles.includes(routeRol));
    });
    return isInRoles;
  };

  const filterUrisByRoles = (validRouters: SiteMap[]) =>
    // route.roles: string[]
    // keycloak?.realmAccess!.roles: string[]
    validRouters.filter((route: SiteMap) => !route.roles || (route.roles && checkIfRoleInRoles(route.roles)));

  const renderRouters = (validRouters: SiteMap[]) => {
    const routes = filterUrisByRoles(validRouters);
    return routes.map((route: SiteMap) => (
      <Route key={route.uri} exact={route.exact} path={route.uri} component={route.component} />
    ));
  };

  const renderContentGrid = (content: React.ReactNode) => (
    <Grid container>
      <Grid item xs={1}></Grid>
      <Grid item xs={10}>
        {content}
      </Grid>
      <Grid item xs={1}></Grid>
    </Grid>
  );

  return (
    <Router>
      <StylesProvider injectFirst>
        <div className={options.className}>
          <Header
            className='header'
            logoContent={
              <Link to={uris.home.uri}>
                <img src={DynamoLogo} alt={DynamoLogo.split('/').pop()} />
              </Link>
            }
            username={username}
            loginUri={uris.login.uri}
            dropdownLinks={filterUrisByRoles(Config.loggedSitemap.filter((url) => !url.hideFromHeader))}
            fastLinks={filterUrisByRoles(Config.loggedSitemap.filter((url) => url.displayAsFastLink))}
          />

          {/* start router's sitemap */}
          {renderContentGrid(
            <div className='page-content'>
              <Switch>
                {keycloak?.authenticated ? renderRouters(Config.loggedSitemap) : renderRouters(Config.anonymousSiteMap)}
              </Switch>
            </div>
          )}
          {/* end router's sitemap */}

          <Footer
            className='footer'
            terms={
              <Link to='#' onClick={preventDefault}>
                Términos de uso
              </Link>
            }
            conditions={
              <Link to='#' onClick={preventDefault}>
                Declaración de privacidad
              </Link>
            }
          />
        </div>
      </StylesProvider>
    </Router>
  );
};

export const App = styled(({ ...props }) => <CleanApp {...props} />)`
  .header {
    background-color: var(--color-white);
    border-bottom: 1px solid var(--color-orange);
    padding: 0;
    box-shadow: none;
    > div {
      padding: 0 30px;
    }
    .header-links,
    .header-grow {
      display: flex;
      flex-grow: 2;
    }
    .header-links {
      justify-content: space-around;
      flex-grow: 1;
      .custom-link {
        position: relative;
        .header-custom-link {
          color: var(--color-grey-text);
          padding: 10px 5px;
          font-weight: bold;
          &.active {
            color: var(--color-black);
          }
        }
        .selected {
          border-bottom: 3px solid var(--color-orange);
          border-radius: 10px;
          width: 30%;
          position: absolute;
          left: 45%;
          bottom: -10px;
        }
      }
    }
    .header-auth {
      padding: 30px 0 30px 20px;
      border-left: 1px solid var(--color-orange);
      h6,
      button {
        color: var(--color-orange);
        padding: 0;
        .auth-avatar {
          background-color: var(--color-orange);
        }
      }
    }
  }
  .footer {
    background-color: var(--color-orange);
  }
  .header,
  .footer {
    a {
      color: var(--color-white);
      font-weight: bold;
      text-decoration: none;
    }
  }
  .footer > div {
    margin: auto;
  }
  .page-content {
    padding: 20px 0;
    min-height: 90vh;
  }
`;
