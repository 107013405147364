import { call, put, takeLatest, all } from 'redux-saga/effects';
import Api from './Api';
import ApiMock from './ApiMock';
import {
  getOrdersGroupedByDateSuccess,
  getTopProductsSuccess,
  getTopSalesTeamsSuccess,
  getTopSalesmenSuccess,
  getOrdersSuccess,
  getOrdersToExportSuccess,
  getOrdersGroupedByDateFail,
  getTopProductsFail,
  getTopSalesTeamsFail,
  getTopSalesmenFail,
  getOrdersFail
} from './actions';
// eslint-disable-next-line no-unused-vars
import { ACTIONS, actionType } from '../ActionTypes';

const api = process.env.REACT_APP_USE_MOCKS === 'true' ? ApiMock() : Api();

function* sagasGetOrdersGroupedByDateFetch(action: actionType) {
  try {
    const response = yield call(api.getOrdersGroupedByDateFetch, {
      params: {
        from_date: action.payload.from_date,
        to_date: action.payload.to_date,
        include_children: action.payload.include_children
      }
    });
    yield put(getOrdersGroupedByDateSuccess(response.data));
  } catch (e) {
    yield put(getOrdersGroupedByDateFail(e));
  }
}

function* sagasGetTopProductsFetch(action: actionType) {
  try {
    const response = yield call(api.getTopProductsFetch, {
      params: {
        from_date: action.payload.from_date,
        to_date: action.payload.to_date,
        include_children: action.payload.include_children,
        sort: action.payload.sort,
        items_limit: action.payload.items_limit
      }
    });
    yield put(getTopProductsSuccess(response.data));
  } catch (e) {
    yield put(getTopProductsFail(e));
  }
}

function* sagasGetTopSalesTeamsFetch(action: actionType) {
  try {
    const response = yield call(api.getTopSalesTeamsFetch, {
      params: {
        from_date: action.payload.from_date,
        to_date: action.payload.to_date,
        include_children: action.payload.include_children,
        sort: action.payload.sort,
        items_limit: action.payload.items_limit
      }
    });
    yield put(getTopSalesTeamsSuccess(response.data));
  } catch (e) {
    yield put(getTopSalesTeamsFail(e));
  }
}

function* sagasGetTopSalesmenFetch(action: actionType) {
  try {
    const response = yield call(api.getTopSalesmenFetch, {
      params: {
        from_date: action.payload.from_date,
        to_date: action.payload.to_date,
        include_children: action.payload.include_children,
        sort: action.payload.sort,
        items_limit: action.payload.items_limit
      }
    });
    yield put(getTopSalesmenSuccess(response.data));
  } catch (e) {
    yield put(getTopSalesmenFail(e));
  }
}

function* sagasGetOrdersFetch(action: actionType) {
  try {
    let response;
    if (action.payload.nextPage)
      response = yield call(api.getOrdersFetch, { params: { nextPage: action.payload.nextPage } });
    else
      response = yield call(api.getOrdersFetch, {
        params: {
          from_date: action.payload.from_date,
          to_date: action.payload.to_date,
          include_children: action.payload.include_children,
          sort: action.payload.sort,
          order_status: action.payload.order_status,
          pagelen: action.payload.pagelen
        }
      });
    yield put(getOrdersSuccess(response.data));
  } catch (e) {
    yield put(getOrdersFail(e));
  }
}

function* sagasGetOrdersToExportFetch(action: actionType) {
  try {
    const response = yield call(api.getOrdersFetch, {
      params: {
        from_date: action.payload.from_date,
        to_date: action.payload.to_date,
        include_children: action.payload.include_children,
        sort: action.payload.sort,
        order_status: action.payload.order_status,
        pagelen: action.payload.pagelen
      }
    });
    yield put(getOrdersToExportSuccess(response.data));
  } catch (e) {
    yield put(getOrdersFail(e));
  }
}

// Listen for redux actions
function* reportsSaga() {
  yield all([takeLatest(ACTIONS.REPORTS.ORDERS_GROUPED_BY_DATE.FETCH, sagasGetOrdersGroupedByDateFetch)]);
  yield all([takeLatest(ACTIONS.REPORTS.PRODUCTS.FETCH, sagasGetTopProductsFetch)]);
  yield all([takeLatest(ACTIONS.REPORTS.SALESTEAMS.FETCH, sagasGetTopSalesTeamsFetch)]);
  yield all([takeLatest(ACTIONS.REPORTS.SALESMEN.FETCH, sagasGetTopSalesmenFetch)]);
  yield all([takeLatest(ACTIONS.REPORTS.ORDERS.FETCH, sagasGetOrdersFetch)]);
  yield all([takeLatest(ACTIONS.REPORTS.ORDERS.TO_EXPORT.FETCH, sagasGetOrdersToExportFetch)]);
  yield all([takeLatest(ACTIONS.REPORTS.ORDERS.NEXT_PAGE, sagasGetOrdersFetch)]);
}

export { reportsSaga };
