import { fork } from 'redux-saga/effects';

import { exampleSaga } from './views/Example';
import { addUserSaga } from './views/AddUser';
import { productsSaga } from './views/Products';
import { checkoutSaga } from './views/Checkout';
import { mySalesSaga } from './views/MySales';
import { usersSaga } from './views/Users';
import { reportsSaga } from './views/Reports';

export default function* rootSaga() {
  yield fork(exampleSaga); // TODO: This is an example
  yield fork(addUserSaga);
  yield fork(productsSaga);
  yield fork(checkoutSaga);
  yield fork(mySalesSaga);
  yield fork(usersSaga);
  yield fork(reportsSaga);
}
