import axios from 'axios';

export interface IReportsParams {
  params: {
    from_date?: string;
    to_date?: string;
    include_children?: boolean;
    order_status?: string;
    sort?: string;
    items_limit?: number;
    nextPage?: string;
    pagelen?: number;
    toExport?: boolean;
  };
}

const Api = () => {
  const getOrdersGroupedByDateFetch = (params: IReportsParams): Promise<unknown> =>
    axios.get(process.env.REACT_APP_REPORTS_GET_ORDERS_GROUPED_BY_DATE_URL!, params);

  const getTopProductsFetch = (params: IReportsParams): Promise<unknown> =>
    axios.get(process.env.REACT_APP_REPORTS_GET_PRODUCTS_URL!, params);

  const getTopSalesTeamsFetch = (params: IReportsParams): Promise<unknown> =>
    axios.get(process.env.REACT_APP_REPORTS_GET_SALESTEAMS_URL!, params);

  const getTopSalesmenFetch = (params: IReportsParams): Promise<unknown> =>
    axios.get(process.env.REACT_APP_REPORTS_GET_SALESMEN_URL!, params);

  const getOrdersFetch = (params: IReportsParams): Promise<unknown> =>
    params.params.nextPage
      ? axios.get(`${process.env.REACT_APP_REPORTS_GET_ORDERS_URL}?${params.params.nextPage}`)
      : axios.get(process.env.REACT_APP_REPORTS_GET_ORDERS_URL!, params);

  return {
    getOrdersGroupedByDateFetch,
    getTopProductsFetch,
    getTopSalesTeamsFetch,
    getTopSalesmenFetch,
    getOrdersFetch
  };
};

export default Api;
