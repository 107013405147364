import { call, put, takeLatest, all } from 'redux-saga/effects';
import Api from './Api';
import ApiMock from './ApiMock';
import { getUsersSuccess, getUsersFail } from './actions';
// eslint-disable-next-line no-unused-vars
import { ACTIONS, actionType } from '../ActionTypes';

const api = process.env.REACT_APP_USE_MOCKS === 'true' ? ApiMock() : Api();

function* sagasGetUsersFetch(action: actionType) {
  try {
    let response;
    if (action.payload && action.payload.nextPage)
      response = yield call(api.getUsersFetch, undefined, undefined, action.payload.nextPage);
    else response = yield call(api.getUsersFetch, action.payload.partnerId, action.payload.collaboratorId);
    yield put(getUsersSuccess(response.data.data.collaborators, response.data.data.next));
  } catch (e) {
    yield put(getUsersFail(e));
  }
}

// Listen for redux actions
function* usersSaga() {
  yield all([takeLatest(ACTIONS.USERS.FETCH, sagasGetUsersFetch)]);
  yield all([takeLatest(ACTIONS.USERS.NEXT_PAGE, sagasGetUsersFetch)]);
}

export { usersSaga };
