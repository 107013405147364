import React, { useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useKeycloak } from '@react-keycloak/web';
import { Analytics, substractTime, TextPoint, Popper, StyledButton, DatePicker, Loading } from '@wdynamo/common';
import { IReportsProps } from './models'; // eslint-disable-line no-unused-vars
import { SalesChart } from './components/Chart';
import { ReportsTabs } from './components/Tabs';
import { OrdersTable } from './components/Table';
import { Grid } from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import moment from 'moment';
import iconExcel from '../../assets/img/icons_dynamo/icon_excel.svg';
import { CSVLink } from 'react-csv'; // eslint-disable-line
interface ReportsComponentProps {
  reportsStore: IReportsProps;
  getOrdersGroupedByDateFetch(from_date: string, to_date: string, include_children: boolean): void;
  getTopProductsFetch(
    from_date: string,
    to_date: string,
    include_children: boolean,
    sort: string,
    items_limit: number
  ): void;
  getTopSalesTeamsFetch(
    from_date: string,
    to_date: string,
    include_children: boolean,
    sort: string,
    items_limit: number
  ): void;
  getTopSalesmenFetch(
    from_date: string,
    to_date: string,
    include_children: boolean,
    sort: string,
    items_limit: number
  ): void;
  getOrdersFetch(
    from_date: string,
    to_date: string,
    include_children: boolean,
    sort: string,
    pagelen?: number,
    order_status?: string
  ): void;
  getOrdersToExportFetch(
    from_date: string,
    to_date: string,
    include_children: boolean,
    sort: string,
    pagelen?: number,
    order_status?: string
  ): void;
  getOrdersNextPageFetch(nextPage: string): void;
}

const useStyles = makeStyles(() =>
  createStyles({
    popper: { maxWidth: '21em', textAlign: 'center' }
  })
);

const CleanReportsComponent: React.FC<ReportsComponentProps> = (props: ReportsComponentProps) => {
  const analytics = Analytics();
  const { t } = useTranslation();
  const { keycloak } = useKeycloak();
  const classes = useStyles();
  const { ordersGroupedByDate, products, salesTeams, salesmen, orders } = props.reportsStore;
  const [firstLoading, setFirstLoading] = useState<boolean>(true);
  const [anchorEl, setAnchorEl] = useState(null);
  const [dateFrom, setDateFrom] = useState<string>('01/01/2020');
  const [dateTo, setDateTo] = useState<string>(moment().format('DD/MM/YYYY').toString());
  const [csvHeaders, setCsvHeaders] = useState<any>();
  const [csvData, setCsvData] = useState<any>();
  const csvInstance = useRef<any>();

  useEffect(() => {
    analytics.pageview('reports');
  }, []);

  useEffect(() => {
    setFirstLoading(false);
  }, [props.reportsStore]);

  //@TODO: this is a workaround because the react-csv isn't woking properyly.
  useEffect(() => {
    if (csvData && csvData.length > 0 && csvInstance.current && csvInstance.current.link) {
      setTimeout(() => {
        csvInstance.current.link.click();
        setCsvData(null);
        setAnchorEl(null);
      });
    }
  }, [csvData]);

  useEffect(() => {
    const headers = keycloak?.realmAccess?.roles.includes(process.env.REACT_APP_KEYCLOAK_PARTNER_ADMIN_ROL!)
      ? [
          { label: t('REPORTS.EXPORT.CSV_HEADERS.ORDER_ID'), key: 'id' },
          { label: t('REPORTS.EXPORT.CSV_HEADERS.CREATED_AT'), key: 'created_at' },
          { label: t('REPORTS.EXPORT.CSV_HEADERS.SUPERVISOR'), key: 'supervisor' },
          { label: t('REPORTS.EXPORT.CSV_HEADERS.USERNAME'), key: 'username' },
          { label: t('REPORTS.EXPORT.CSV_HEADERS.ORDER_STATUS'), key: 'status' },
          { label: t('REPORTS.EXPORT.CSV_HEADERS.ORDER_TOTAL'), key: 'amount' },
          { label: t('REPORTS.EXPORT.CSV_HEADERS.PRODUCTS'), key: 'products' }
        ]
      : [
          { label: t('REPORTS.EXPORT.CSV_HEADERS.ORDER_ID'), key: 'id' },
          { label: t('REPORTS.EXPORT.CSV_HEADERS.CREATED_AT'), key: 'created_at' },
          { label: t('REPORTS.EXPORT.CSV_HEADERS.USERNAME'), key: 'username' },
          { label: t('REPORTS.EXPORT.CSV_HEADERS.ORDER_STATUS'), key: 'status' },
          { label: t('REPORTS.EXPORT.CSV_HEADERS.ORDER_TOTAL'), key: 'amount' },
          { label: t('REPORTS.EXPORT.CSV_HEADERS.PRODUCTS'), key: 'products' }
        ];
    const data = orders.ordersToExport.map((order) => ({
      id: order.id,
      created_at: moment(order.created_at).toLocaleString(),
      supervisor: keycloak?.realmAccess?.roles.includes(process.env.REACT_APP_KEYCLOAK_PARTNER_ADMIN_ROL!)
        ? order.collaborator.parent_collaborators.length > 0
          ? order.collaborator.parent_collaborators.map((collaborator) => collaborator.username)
          : '-'
        : undefined,
      username: order.collaborator.username,
      status: t(`REPORTS.TOOLTIPS.${order.status}`),
      amount: order.items.reduce((total, item) => (total += item.amount * item.price.amount), 0),
      products: order.items.map((item) => item.title)
    }));
    setCsvHeaders(headers);
    setCsvData(data);
  }, [orders.ordersToExport]);

  useEffect(() => {
    if (keycloak?.authenticated) {
      setTimeout(() => {
        const to_date = moment().toISOString();
        const from_date = moment(substractTime(to_date, 'months', 6)).toISOString();
        props.getOrdersGroupedByDateFetch(from_date, to_date, true);
        props.getOrdersFetch(from_date, to_date, true, '-created_at');
        props.getTopProductsFetch(from_date, to_date, true, '-sales_amount', 4);
      });
    }
  }, [keycloak?.authenticated]);

  const generateDataToExport = () => {
    props.getOrdersToExportFetch(
      moment(dateFrom, 'DD/MM/YYYY').toISOString(),
      moment(dateTo, 'DD/MM/YYYY').toISOString(),
      true,
      '-created_at',
      1000
    );
  };

  const togglePopper = (event: any) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  return (
    <Grid container>
      <Grid container justify='space-between' alignItems='flex-end'>
        <Grid item>
          <h1>
            {t('REPORTS.TITLE')}
            <TextPoint />
          </h1>
        </Grid>
        <Grid item>
          <img src={iconExcel} onClick={togglePopper} />
          <Popper anchorEl={anchorEl}>
            {csvData ? (
              <CSVLink
                headers={csvHeaders}
                data={csvData}
                filename={`WDynamo_Orders_Report_${moment().format('YYYYMMDD').toString()}.csv`}
                ref={csvInstance}
              />
            ) : undefined}
            {orders.loading ? (
              <Loading />
            ) : (
              <>
                <Grid container spacing={3} justify='center' className={classes.popper}>
                  <Grid item xs={6}>
                    <DatePicker
                      label={t('REPORTS.EXPORT.DATE_FROM')}
                      value={dateFrom}
                      onChange={(value: string): void => setDateFrom(value)}
                      testId='dateFrom'
                      format={process.env.REACT_APP_DATE_FORMAT!}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <DatePicker
                      label={t('REPORTS.EXPORT.DATE_TO')}
                      value={dateTo}
                      onChange={(value: string): void => setDateTo(value)}
                      testId='dateTo'
                      format={process.env.REACT_APP_DATE_FORMAT!}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <StyledButton className='default' onClick={generateDataToExport}>
                      {t('REPORTS.EXPORT.BUTTON')}
                    </StyledButton>
                  </Grid>
                  <Grid item xs={12}>
                    <StyledButton className='cancel'>{t('COMMONS.BUTTONS.CANCEL')}</StyledButton>
                  </Grid>
                </Grid>
              </>
            )}
          </Popper>
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <h3>
            {t('REPORTS.CHART.TITLE')}
            <TextPoint />
          </h3>
          <SalesChart ordersGroupedByDate={ordersGroupedByDate} />
        </Grid>
        <Grid item xs={12} md={6}>
          <h3>
            {t('REPORTS.TABS.TITLE')}
            <TextPoint />
          </h3>
          <ReportsTabs
            products={products}
            salesTeams={salesTeams}
            salesmen={salesmen}
            getTopProductsFetch={props.getTopProductsFetch}
            getTopSalesTeamsFetch={props.getTopSalesTeamsFetch}
            getTopSalesmenFetch={props.getTopSalesmenFetch}
          />
        </Grid>
        <Grid item xs={12}>
          <h3>
            {t('REPORTS.TABLE.TITLE')}
            <TextPoint />
          </h3>
          <OrdersTable orders={orders} firstLoading={firstLoading} paginate={props.getOrdersNextPageFetch} />
        </Grid>
      </Grid>
    </Grid>
  );
};

export const ReportsComponent = (props: ReportsComponentProps) => <CleanReportsComponent {...props} />;
