// eslint-disable-next-line no-unused-vars
import { ACTIONS, actionType } from '../ActionTypes';
// eslint-disable-next-line no-unused-vars
import { IUsersProps } from './models';

const initialState: IUsersProps = {
  loading: false,
  error: false,
  users: [],
  nextPage: undefined
};

export const usersReducer = (state = initialState, action: actionType) => {
  switch (action.type) {
    case ACTIONS.USERS.FETCH:
      return {
        ...state,
        loading: true,
        error: false,
        users: [],
        nextPage: undefined
      };
    case ACTIONS.USERS.SUCCESS:
      return {
        ...state,
        loading: false,
        users: [...state.users, ...action.payload.users],
        nextPage: action.payload.next
      };
    case ACTIONS.USERS.FAIL:
      return {
        ...state,
        loading: false,
        error: true
      };
    case ACTIONS.USERS.NEXT_PAGE:
      return {
        ...state,
        loading: true
      };

    default:
      return state;
  }
};
