import axios from 'axios';

const Api = () => {
  const getUsersFetch = (partnerId?: string, collaboratorId?: string, nextPage?: string): Promise<unknown> =>
    nextPage
      ? axios.get(nextPage)
      : axios.get(`${process.env.REACT_APP_GET_USERS_URL}/${partnerId}/collaborators/${collaboratorId}/children`);

  return { getUsersFetch };
};

export default Api;
