import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useKeycloak } from '@react-keycloak/web';

import { Analytics, TextPoint } from '@wdynamo/common';

// eslint-disable-next-line no-unused-vars
import { IMySalesProps } from './models';
import { Dashboard } from './components/dashboard';
import { OrdersScroll } from './components/ordersScroll';

interface MySalesComponentProps {
  [key: string]: any;
  mySalesStore: IMySalesProps;
  getMySalesFetch(): void;
  getMySalesNextPageFetch(nextPage: string): void;
  getMySalesDashboardFetch(): void;
  className?: string;
}

const CleanMySalesComponent: React.FC<MySalesComponentProps> = (props: MySalesComponentProps) => {
  const analytics = Analytics();
  const { keycloak } = useKeycloak();
  const { mySalesStore } = props;
  const { t } = useTranslation();
  const [firstLoading, setFirstLoading] = useState<boolean>(true);

  useEffect(() => {
    analytics.pageview('my-sales');
  }, []);

  useEffect(() => {
    if (keycloak?.authenticated)
      setTimeout(() => {
        props.getMySalesFetch();
        props.getMySalesDashboardFetch();
      });
  }, [keycloak?.authenticated]);

  useEffect(() => {
    setFirstLoading(false);
  }, [mySalesStore]);

  return (
    <div className={`${props.className} my-sales`}>
      <h1>
        {t('MY_SALES.TITLE')}
        <TextPoint />
      </h1>
      <Dashboard dashboardData={mySalesStore.dashboard} />

      <OrdersScroll
        ordersData={mySalesStore.table}
        firstLoading={firstLoading}
        paginate={props.getMySalesNextPageFetch}
      />
    </div>
  );
};

export const MySalesComponent = styled(({ ...props }) => <CleanMySalesComponent {...props} />)``;
