import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useKeycloak } from '@react-keycloak/web';
import { Grid } from '@material-ui/core';

import { Analytics } from '@wdynamo/common/lib/services';
import { CustomInfiniteScroll, Loading } from '@wdynamo/common';
// eslint-disable-next-line no-unused-vars
import { IUsersProps, IUser } from './models';

interface IUsersScrollProps {
  [key: string]: any;
  usersStore: IUsersProps;
  firstLoading: boolean;
  getUsersFetch(partnerId: string, collaboratorId: string, nextPage?: string): void;
  getUsersNextPageFetch(nextPage?: string): void;
  className?: string;
}

const CleanUsersComponent: React.FC<IUsersScrollProps> = (props: IUsersScrollProps) => {
  const analytics = Analytics();
  const { keycloak } = useKeycloak();
  const { t } = useTranslation();
  const { usersStore } = props;
  const [isPaginating, setIsPaginating] = useState(false);

  useEffect(() => {
    analytics.pageview('display-users');
  }, []);

  useEffect(() => {
    if (keycloak?.authenticated)
      setTimeout(() => {
        keycloak
          ?.loadUserInfo()
          .then((userInfo: any) => props.getUsersFetch(userInfo.partner_id, userInfo.collaborator_id));
      });
  }, [keycloak?.authenticated]);

  useEffect(() => {
    setIsPaginating(usersStore.loading);
  }, [usersStore.loading]);

  const handleChangePage = (): void => {
    if (usersStore.nextPage && !usersStore.loading && !isPaginating) {
      setIsPaginating(true);
      props.getUsersNextPageFetch(usersStore.nextPage);
    }
  };

  const renderUsersTable = (): React.ReactNode => {
    const { loading, users, nextPage } = usersStore;

    if (!users.length && loading) return <Loading />;
    else if (!users.length) return <></>;
    else
      return (
        <>
          <h3>
            {t('USERS.TABLE.TITLE')}
            <text className='orangeText'>.</text>
          </h3>
          <Grid container className='users-grid'>
            <Grid item xs={12} className='header'>
              <Grid container alignItems='center'>
                <Grid item xs={3} md={2}>
                  {t('USERS.TABLE.HEADERS.COLLABORATOR_ID')}
                </Grid>
                <Grid item xs={4} md={2}>
                  {t('USERS.TABLE.HEADERS.PARTNER_ID')}
                </Grid>
                <Grid item xs={5} md={2}>
                  {t('USERS.TABLE.HEADERS.USERNAME')}
                </Grid>
                <Grid item xs={7} md={3}>
                  {t('USERS.TABLE.HEADERS.EMAIL')}
                </Grid>
                <Grid item xs={3} md={1}></Grid>
                <Grid item xs={3} md={2}>
                  {t('USERS.TABLE.HEADERS.PARENT_COLLABORATOR_IDS')}
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12} className='content'>
              <CustomInfiniteScroll nextUrl={nextPage} paginate={handleChangePage} firstLoading={props.firstLoading}>
                {users.map((user: IUser) => (
                  <Grid container key={user.id} className='user' alignItems='center'>
                    <Grid item xs={3} md={2}>
                      {user.collaborator_id}
                    </Grid>
                    <Grid item xs={4} md={2}>
                      {user.partner_id}
                    </Grid>
                    <Grid item xs={5} md={2}>
                      {user.username}
                    </Grid>
                    <Grid item xs={7} md={3}>
                      {user.email}
                    </Grid>
                    <Grid item xs={3} md={1}></Grid>
                    <Grid item xs={3} md={2}>
                      {user.parent_collaborator_ids.map((parentId) => (
                        <Grid item key={parentId}>
                          * {parentId}
                        </Grid>
                      ))}
                    </Grid>
                  </Grid>
                ))}
              </CustomInfiniteScroll>
            </Grid>
          </Grid>
        </>
      );
  };

  return (
    <div className={props.className}>
      <h1>
        {t('USERS.TITLE')}
        <text className='orangeText'>.</text>
      </h1>
      {renderUsersTable()}
    </div>
  );
};

export const UsersComponent = styled(({ ...props }) => <CleanUsersComponent {...props} />)`
  .users-grid {
    .header {
      border: none;
      font-weight: bold;
      text-align: left;
      div {
        padding: 5px 0;
      }
    }
    .content {
      .user {
        padding: 10px 0;
        border-bottom: 1px solid var(--color-grey-border);
      }
      .checkbox {
        color: var(--color-orange);
      }
    }
  }
`;
