import { ACTIONS } from '../ActionTypes';

// Get my sales's table
export const getMySalesFetch = () => ({ type: ACTIONS.MY_SALES.TABLE.FETCH });

export const getMySalesSuccess = (data: object) => ({ type: ACTIONS.MY_SALES.TABLE.SUCCESS, payload: { data } });

export const getMySalesFail = (error: object) => ({ type: ACTIONS.MY_SALES.TABLE.FAIL, payload: { error } });

export const getMySalesNextPageFetch = (nextPage: string) => ({
  type: ACTIONS.MY_SALES.TABLE.NEXT_PAGE,
  payload: { nextPage }
});

// Get my sales's dashboard
export const getMySalesDashboardFetch = () => ({ type: ACTIONS.MY_SALES.DASHBOARD.FETCH });

export const getMySalesDashboardSuccess = (data: object) => ({
  type: ACTIONS.MY_SALES.DASHBOARD.SUCCESS,
  payload: { data }
});

export const getMySalesDashboardFail = (error: object) => ({
  type: ACTIONS.MY_SALES.DASHBOARD.FAIL,
  payload: { error }
});
